$white: #ffffff;
$purple: #9d5baf;
$dark-purple: #2a0b3a;

.fileUpload {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.fileUploadLabelFocused {
  outline: 0;
  background-color: $dark-purple;
  box-shadow: 0 0 0 2px $white, 0 0 0 4px $purple;
}
